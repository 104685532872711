import React from "react";
import styles from "./app.module.scss";
import { useLocation } from "react-router-dom";
import "./theme/material-dark.css";
import "./theme/typography.scss";
import "./theme/components/datagrid.scss";
import "./theme/components/treelist.scss";
import "./theme/components/treeview.scss";
import "./theme/components/form.scss";
import "./theme/components/toolbar.scss";
import Dbd from "./dbd";

function Component() {
  //
  // hooks

  let location = useLocation();

  // render

  function render() {
    if (location.pathname.includes("dbd")) return <Dbd />;
    else return null;
  }

  return <div className={styles.main}>{render()}</div>;
}

export default Component;
