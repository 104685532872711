import React, { useState, useContext, useEffect } from "react";
import Hierarchy from "components/hierarchy";
import { LoadingIndicator2 as LoadingIndicator } from "@kayugasolution/ui";
import { request } from "helper/http-client";
import { url } from "config.js";
import HierarchyContext from "components/hierarchy/context";
import { DataGrid1 as Grid } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { Column } from "devextreme-react/data-grid";
import { currency } from "helper/global";

function Component(props) {
  // render

  return (
    <Hierarchy id={props.id} showCount={true} showFilter={false}>
      <Dashboard />
    </Hierarchy>
  );
}

export default Component;

function Dashboard(props) {
  //
  //hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  const store = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/dbd/13`,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            filterExpression: filter,
          },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  return (
    <Grid
      showTitle={false}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={true}
      allowFiltering={false}
      allowSorting={false}
      dataSource={store}
    >
      <Column visible={false} dataField={"id"} />
      <Column caption={"Name"} dataField={"name"} />
      <Column caption={"Count"} dataField={"count"} format={`#,###`} />
      <Column caption={"Length"} dataField={"length"} format={`#,###`} />
      <Column caption={"Total Replacement Cost"} dataField={"cost"} format={`${currency()}#,###`} />
      <Column caption={"% of Total Replacement Cost"} dataField={"percentCost"} format={`##.##`} />
    </Grid>
  );
}
