import React, { useRef, useEffect } from "react";
import { JsonEditor } from "react-jsondata-editor";
import styles from "./styles.module.scss";
import { SpeedDialAction } from "devextreme-react/speed-dial-action";
import config from "devextreme/core/config";
import repaintFloatingActionButton from "devextreme/ui/speed_dial_action/repaint_floating_action_button";
import { notify, dict } from "helper/global";
import { request } from "helper/http-client";
import { url } from "config.js";
import { confirm } from "devextreme/ui/dialog";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // hooks

  useEffect(() => {
    config({
      floatingActionButtonConfig: {
        direction: "up",
        position: { at: "right bottom", my: "right bottom", offset: "-50 -50" },
        shading: true,
      },
    });
    repaintFloatingActionButton();
  });

  const json = useRef({});

  // styles

  const myStyle = {
    theme: {
      color: "#292929",
      hoverColor: "#373737",
    },
    bannerStyle: {
      hoverColor: "#373737",
      fontColor: "rgba(255, 255, 255, 0.6)",
      font: '16px/16px "Roboto", Times, serif',
    },
    keyStyle: {
      color: "#8ed962",
      font: '18px/30px "Roboto", Times, serif',
    },
    valueStyle: {
      font: '18px/30px "Roboto", Times, serif',
      null: "#bb626a",
      boolean: "#e8c267",
      number: "#85dbd5",
      string: "#9685cb",
    },
    buttonStyle: {
      add: "#98c21f",
      delete: "#bb86fc",
      update: "#98c21f",
      cancel: "#939597",
    },
  };

  console.log(props.data.settings);

  // event handlers

  async function onSave() {
    var result = await confirm(`<i>${dict("Do you really want to save the settings")}?</i>`, "Confirm");
    if (result) {
      try {
        await request({ url: `${url}/uicomponent`, method: "Put", data: { ...props.data, settings: json.current } });
        props.onRefresh();
      } catch (error) {
        notify(error.message);
      }
    }
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <SpeedDialAction icon="save" index={1} label={"save"} onClick={onSave} />
        <JsonEditor
          jsonObject={props.data.settings}
          theme={myStyle.theme}
          bannerStyle={myStyle.bannerStyle}
          keyStyle={myStyle.keyStyle}
          valueStyle={myStyle.valueStyle}
          buttonStyle={myStyle.buttonStyle}
          onChange={(output) => {
            json.current = output;
          }}
        />
      </div>
    </div>
  );
}

export default withMemo(Component, ["data"]);
