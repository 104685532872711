import React, { useEffect, useContext, useState } from "react";
import styles from "./styles.module.scss";
import { Card1 as Card } from "@kayugasolution/ui";
import { faSirenOn, faTruckPickup, faCalendar, faMoneyCheckEdit, faSack } from "@fortawesome/pro-duotone-svg-icons";
import { commaFormat, currencyFormat } from "helper/global";
import HierarchyContext from "components/hierarchy/context";
import { request } from "helper/http-client";
import { url } from "config.js";
import { LoadingIndicator2 as LoadingIndicator } from "@kayugasolution/ui";
import Box from "components/box";

function Component(props) {
  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  const [state, setState] = useState(null);

  useEffect(() => {
    setState(null);
    (async () => {
      const result = await request({
        url: `${url}/dbd/1/header`,
        params: { ids: selectedRowsData.map((i) => i.id), filterExpression: filter },
      });
      setState(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, filter]);

  return state ? (
    <Box title="Status">
      <div className={styles.main}>
        <div className={styles.grid}>
          <Card title={"Total Number of Assets"} value={commaFormat(state.count)} cursor={true} icon={faTruckPickup} color={"#5f8b95"} />
          <Card title={"Total Replacement Cost"} value={currencyFormat(state.cost)} cursor={true} icon={faSack} color={"#BA4D51"} />
          <Card title={"Annualized R&R"} value={currencyFormat(state.annualCost)} cursor={true} icon={faSack} color={"#859666"} />
          <div className={styles.empty}></div>
        </div>
      </div>
    </Box>
  ) : (
    <LoadingIndicator />
  );
}

export default Component;
