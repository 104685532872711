import React from "react";
import { PieChart } from "@kayugasolution/ui";
import { connect } from "react-redux";

function Component(props) {
  // let palette =
  //   (props.chartId &&
  //     props.charts.length > 0 &&
  //     props.charts.find((i) => i.chartId === props.chartId)?.name) ||
  //   "Office";

  //
  // render

  return <PieChart {...props}>{props.children}</PieChart>;
}

export default Component;
