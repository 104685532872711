import React, { useRef, useEffect } from "react";
import TreeList, { Column, RowDragging, Editing, Popup, Position, FilterRow, StateStoring } from "devextreme-react/tree-list";
import { Template } from "devextreme-react/core/template";
import { RequiredRule } from "devextreme-react/validator";
import { connect } from "react-redux";
import { request } from "helper/http-client";
import { url } from "config.js";
import CustomStore from "devextreme/data/custom_store";
import { dict, withMemo } from "helper/global";

function Component(props) {
  //
  // stores

  const nodeStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/dbd/hierarchy/${props.id}`, loadOptions: loadOptions });
      },
    }),
  };

  // hooks

  const treeList = useRef();
  const rendered = useRef(false);

  useEffect(() => {
    treeList.current.instance.option("selection.mode", "single");
    treeList.current.instance.option("rowDragging.allowDropInsideItem", false);
  }, []);

  useEffect(() => {
    rendered.current = true;
  });

  // events handlers

  async function toggleSelectionMode() {
    let selectedRowKeys = treeList.current.instance.option("selectedRowKeys");
    if (selectedRowKeys) treeList.current.instance.selectRows(selectedRowKeys, false);
    else treeList.current.instance.selectRowsByIndexes([0]);
    let optionValue = treeList.current.instance.option("selection.mode");
    optionValue = optionValue === "single" ? "multiple" : "single";
    treeList.current.instance.option("selection.mode", optionValue);
  }

  rendered.current = false;
  treeList.current && treeList.current.instance.clearSelection();

  function onContentReady(e) {
    var selectionMode = e.component.option("selection").mode;
    let selectedRowKeys = e.component.option("selectedRowKeys");
    if (selectionMode === "single" && selectedRowKeys.length === 0 && e.component.totalCount() > 0) {
      e.component.selectRowsByIndexes([0]);
      e.component.forEachNode((node) => {
        if (node.level < 1) {
          e.component.expandRow(node.key);
        }
      });
    }
  }

  function onKeyDown(e) {
    var selKey = e.component.getSelectedRowKeys();
    if (selKey.length) {
      var currentKey = selKey[0];
      var index = e.component.getRowIndexByKey(currentKey);
      if (e.event.keyCode === 38) {
        index--;
        if (index >= 0) {
          e.component.selectRowsByIndexes([index]);
          e.event.stopPropagation();
        }
      } else if (e.event.keyCode === 40) {
        index++;
        e.component.selectRowsByIndexes([index]);
        e.event.stopPropagation();
      }
    }
  }
  //render

  function titleRender() {
    return (
      <div className={`theme-grid-title`}>
        <div>{dict("Hierarchy")}</div>
      </div>
    );
  }

  function onContextMenuPreparing(e) {
    if (!e.items) e.items = [];

    e.items.push({
      text: "Allow Multi-Select",
      selected: treeList.current.instance.option("selection.mode") === "multiple",
      icon: "fas fa-check-square",
      visible: props.multiSelect,
      onItemClick: toggleSelectionMode,
      beginGroup: true,
    });

    props.onContextMenuPreparing && props.onContextMenuPreparing(e);
  }

  function onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: "before",
      template: "titleRender",
    });
    props.onToolbarPreparing && props.onToolbarPreparing(e);
  }

  return (
    <TreeList
      ref={treeList}
      dataSource={nodeStore}
      rootValue={null}
      selection={{ recursive: true }}
      showBorders={true}
      columnAutoWidth={true}
      showRowLines={false}
      keyExpr="id"
      parentIdExpr="parentId"
      hasItemsExpr="hasChildren"
      height={"100%"}
      showColumnHeaders={false}
      allowColumnReordering={true}
      allowColumnResizing={true}
      columnResizingMode="widget"
      onInitialized={props.onInitialized}
      onContentReady={onContentReady}
      onToolbarPreparing={onToolbarPreparing}
      onContextMenuPreparing={onContextMenuPreparing}
      onSelectionChanged={props.onSelectionChanged}
      onKeyDown={onKeyDown}
    >
      {/* <StateStoring enabled={true} type="custom" customLoad={customLoad} customSave={customSave} /> */}
      <Editing mode={"popup"} allowUpdating={true} allowDeleting={true}>
        <Popup title={dict("Hierarchy")} showTitle={true} width={700} height={700}>
          <Position my="center" at="center" of={window} />
        </Popup>
      </Editing>
      <FilterRow visible={true} />
      <Template name="titleRender" render={titleRender} />
      {props.rowNumbering ? (
        <Column
          key={"#"}
          caption={"#"}
          width={75}
          allowFiltering={true}
          allowEditing={true}
          cellRender={(e) => {
            return <div>{e.row.rowIndex + 1}</div>;
          }}
        />
      ) : null}
      <Column caption={"AssetId"} dataField="name" visible={false} allowEditing={true} formItem={{ label: { text: "AssetId" } }}>
        <RequiredRule />
      </Column>
      <Column dataField="description" caption="">
        <RequiredRule />
      </Column>
      <Column visible={false} formItem={{ itemType: "empty" }} />
      1
      <Column
        caption={"Notes"}
        dataField={"notes"}
        allowFiltering={true}
        width={400}
        visible={false}
        formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
      />
      <Column type={"buttons"} width={85} visible={false} />
    </TreeList>
  );
}

export default withMemo(Component, ["id", "render"]);
