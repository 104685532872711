import React from "react";
import styles from "./styles.module.scss";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { Tabs } from "@kayugasolution/ui";
import { selectedTabItem } from "helper/global";
import Hierarchy from "components/hierarchy";
import Header from "./header";
import State from "./state";
import Risk from "./risk";
import CostProfile from "./costprofile";

function Component(props) {
  //
  // hooks

  var location = useLocation();
  var history = useHistory();

  // event handlers

  function changeView(e) {
    history.push(`/dbd/dashboard/${props.id}/${props.typeId}${e.path}`);
  }

  // render

  const tabItems = [
    {
      text: "State",
      icon: "fad fa-thermometer",
      path: "/state",
    },
    {
      text: "Forecast",
      icon: "fad fa-chart-bar",
      path: "/forecast",
    },
    {
      text: "Risk",
      icon: "fad fa-heart-rate",
      path: "/risk",
    },
  ];

  function tabRender() {
    return <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, location.pathname, 5)} changeView={changeView}></Tabs>;
  }

  // render

  function stateRender() {
    return <State />;
  }

  function forecastRender() {
    return <CostProfile />;
  }

  function riskRender() {
    return <Risk forecast={false} />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>{tabRender()}</div>
      <div className={styles.container}>
        <Hierarchy
          id={props.id}
          showCount={true}
          showFilter={true}
          filterFields={["Class", "Type", "Size", "Material", "Manufacturer", "Status", "Ownership", "Group", "Tag", "Risk", "Condition", "CoF"]}
          filterType={1}
        >
          <div className={styles.content}>
            <div className={styles.header}>
              <Header />
            </div>
            <div className={styles.body}>
              <Switch>
                <Route
                  exact
                  path={[`/dbd/dashboard/${props.id}/${props.typeId}`, `/dbd/dashboard/${props.id}/${props.typeId}/state`]}
                  render={stateRender}
                />
                <Route exact path={`/dbd/dashboard/${props.id}/${props.typeId}/forecast`} render={forecastRender} />
                <Route exact path={`/dbd/dashboard/${props.id}/${props.typeId}/risk`} render={riskRender} />
              </Switch>
            </div>
          </div>
        </Hierarchy>
      </div>
    </div>
  );
}

export default Component;
