import React from "react";
import styles from "./styles.module.scss";
import { Route, Switch } from "react-router-dom";
import { Tabs } from "@kayugasolution/ui";
import { selectedTabItem } from "helper/global";
import Dashboard from "./dashboard";
// import Palette from "./palette";
import UIComponent from "./uicomponent";

function Component(props) {
  //
  // event handlers

  function changeView(e) {
    props.history.push(`/dbd/admin/settings${e.path}`);
  }

  // render

  const tabItems = [
    {
      text: "Dashboard",
      icon: "fad fa-tachometer-alt-average",
      path: "/dashboard",
    },
    // {
    //   text: "Palette",
    //   icon: "fad fa-palette",
    //   path: "/palette",
    // },
    {
      text: "Charts",
      icon: "fad fa-palette",
      path: "/uicomponent",
    },
  ];

  function tabRender() {
    return <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView}></Tabs>;
  }

  // render

  function dashboardRender() {
    return <Dashboard />;
  }

  // function paletteRender() {
  //   return <Palette />;
  // }

  function uicomponentRender() {
    return <UIComponent />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>{tabRender()}</div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/dbd/admin/settings", "/dbd/admin/settings/dashboard"]} render={dashboardRender} />
          {/* <Route exact path="/dbd/admin/settings/palette" render={paletteRender} /> */}
          <Route exact path="/dbd/admin/settings/uicomponent" render={uicomponentRender} />
        </Switch>
      </div>
    </div>
  );
}

export default Component;
