import React from "react";
import { Chart } from "@kayugasolution/ui";
import { connect } from "react-redux";

function Component(props) {
  let palette = (props.chartId && props.charts.length > 0 && props.charts.find((i) => i.chartId === props.chartId)?.name) || "Office";

  //
  // render

  return (
    <Chart {...props} palette={palette}>
      {props.children}
    </Chart>
  );
}

const mapStateToProps = (state) => {
  return {
    charts: state.palette.charts,
  };
};

export default connect(mapStateToProps, null)(Component);
