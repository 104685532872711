import React from "react";
import styles from "./styles.module.scss";
import { Route, Switch } from "react-router-dom";
import { Tabs } from "@kayugasolution/ui";
import { selectedTabItem } from "helper/global";
import User from "./user";
import Department from "./department";

function Component(props) {
  //
  // event handlers

  function changeView(e) {
    props.history.push(`/dbd/admin/access${e.path}`);
  }

  // render

  const tabItems = [
    {
      text: "User",
      icon: "fad fa-database",
      path: "/user",
    },
    {
      text: "Department",
      icon: "fad fa-database",
      path: "/department",
    },
  ];

  function tabRender() {
    return <Tabs tabItems={tabItems} selectedItem={selectedTabItem(tabItems, props.location.pathname, 4)} changeView={changeView}></Tabs>;
  }

  // render

  function userRender() {
    return <User />;
  }

  function departmentRender() {
    return <Department />;
  }

  return (
    <div className={styles.main}>
      <div className={styles.tabs}>{tabRender()}</div>
      <div className={styles.container}>
        <Switch>
          <Route exact path={["/dbd/admin/access", "/dbd/admin/access/user"]} render={userRender} />
          <Route exact path="/dbd/admin/access/department" render={departmentRender} />
        </Switch>
      </div>
    </div>
  );
}

export default Component;
