import React, { useEffect, useContext, useRef } from "react";
import styles from "./styles.module.scss";
import { request } from "helper/http-client";
import { url } from "config.js";
import Chart from "components/chart";
import { currencyFormat } from "helper/global";
import HierarchyContext from "components/hierarchy/context";
import {
  CommonSeriesSettings,
  CommonAxisSettings,
  Series,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  MinorTick,
  Format,
  Tooltip,
  ConstantLine,
  ZoomAndPan,
  Font,
} from "devextreme-react/chart";
import Toolbar, { Item } from "devextreme-react/toolbar";
import ChartExpand from "components/chartExpand";
import { useImmerReducer } from "use-immer";
import { connect } from "react-redux";
import Activity from "components/activity-grid";
import { LoadIndicator } from "devextreme-react/load-indicator";

function reducer(draft, action) {
  switch (action.type) {
    case "data":
      draft.data = action.payload;
      draft.loading = false;
      return;
    case "year":
      draft.year = action.payload.year;
      if (action.payload.render) {
        draft.data = null;
        draft.render++;
      }
      return;
    case "escalate":
      draft.escalate = !draft.escalate;
      draft.render++;
      return;
    case "loading":
      draft.loading = !draft.loading;
      return;
    default:
      return;
  }
}

function Component(props) {
  //
  // hooks

  const intialState = {
    data: null,
    year: null,
    escalate: false,
    render: 0,
    loading: false,
  };

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  const [state, dispatch] = useImmerReducer(reducer, intialState);
  const chartRef = useRef(null);
  const renderChart = useRef(false);

  useEffect(() => {
    if (state.year) {
      renderChart.current = true;
      return;
    }
    (async () => {
      try {
        dispatch({ type: "loading" });
        var result = await request({
          url: `${url}/dbd/11/funds`,
          params: { ids: selectedRowsData.map((i) => i.id), escalate: state.escalate, filterExpression: filter },
        });
        dispatch({ type: "data", payload: result });
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, filter, state.render]);

  function onPointClick(e) {
    var year = e.target.originalArgument;
    dispatch({ type: "year", payload: { year: year } });
  }

  function customizeText(e) {
    return { text: `${e.seriesName} - Year: ${e.argument}, Value: ${currencyFormat(e.value)}` };
  }

  function onEscalateChanged(e) {
    dispatch({ type: "escalate" });
  }

  function onBackButtonClick() {
    dispatch({ type: "year", payload: { year: null, render: renderChart.current } });
    renderChart.current = false;
  }

  // render

  function seriesRender() {
    var series = [];
    state.data.funds.forEach((i) => {
      var valueField = `${i.name}`;
      series.push(<Series key={valueField} valueField={valueField} name={valueField} />);
    });
    return series;
  }

  function activityRender() {
    return (
      <div className={styles.grid}>
        <Activity
          url={`${url}/dbd/11/activities`}
          selectedRowsData={selectedRowsData}
          filter={filter}
          year={state.year}
          onBackButtonClick={onBackButtonClick}
        ></Activity>
      </div>
    );
  }

  function indicatorRender() {
    return (
      <div className={styles.loading}>
        <LoadIndicator />
      </div>
    );
  }

  function chartRender() {
    return (
      <div className={styles.chart}>
        <ChartExpand componentId={21101}>
          <Chart dataSource={state.data.profile} onPointClick={onPointClick} chartId={21101}>
            <CommonSeriesSettings argumentField="year" type="stackedBar"></CommonSeriesSettings>
            <CommonAxisSettings>
              <Label>
                <Font size={14} />
              </Label>
            </CommonAxisSettings>
            <Tooltip enabled={true} customizeTooltip={customizeText} />
            <ArgumentAxis name="" allowDecimals={false} minorTickCount={4} visualRange={[props.firstYear + 1, props.lastYear - 1]}>
              <MinorTick visible={true} />
              <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
                <Format type="decimal" />
              </Label>
            </ArgumentAxis>
            <ValueAxis name="" title={"$"}>
              <ConstantLine width={2} value={state.data.annualCost} color={"#bb86fc"} dashStyle={"longDash"}>
                <Label visible={false} horizontalAlignment={"center"}>
                  <Font color={"#fff"} weight={500}></Font>
                </Label>
              </ConstantLine>
            </ValueAxis>
            <ZoomAndPan argumentAxis="both" valueAxis="both" />
            <Legend position="outside" orientation="horizontal" verticalAlignment="top" horizontalAlignment="center">
              <Font size={14} />
            </Legend>
            {seriesRender()}
          </Chart>
        </ChartExpand>
      </div>
    );
  }
  return (
    <div className={styles.main}>
      {state.year ? (
        activityRender()
      ) : (
        <React.Fragment>
          {state.data && chartRender()}
          {state.loading && indicatorRender()}
        </React.Fragment>
      )}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    firstYear: state.scenario.firstYear,
    lastYear: state.scenario.firstYear + state.scenario.planningHorizon,
  };
};

export default connect(mapStateToProps, null)(Component);
