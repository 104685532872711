import React, { useState, useRef, useEffect } from "react";
import { ErrorPage } from "@kayugasolution/ui";
import { useParams } from "react-router-dom";
import Dashboard1 from "./dashboard1";
import Dashboard2 from "./dashboard2";
import Dashboard3 from "./dashboard3";
import Dashboard4 from "./dashboard4";
import Dashboard5 from "./dashboard5";
import Dashboard6 from "./dashboard6";
import Dashboard7 from "./dashboard7";
import Dashboard8 from "./dashboard8";
import Dashboard9 from "./dashboard9";
import Dashboard10 from "./dashboard10";
import Dashboard11 from "./dashboard11";
import Dashboard12 from "./dashboard12";
import Dashboard13 from "./dashboard13";
import Dashboard14 from "./dashboard14";

function Component() {
  //
  // hooks
  let { id, typeId } = useParams();
  const typeIdRef = useRef(null);
  const prevId = useRef(null);
  const prevTypeId = useRef(null);
  const [_, setState] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (typeIdRef.current === null) {
      typeIdRef.current = typeId;
      setState((state) => state + 1);
    } else typeIdRef.current = null;

    prevId.current = id;
    prevTypeId.current = typeId;
  });

  typeIdRef.current = typeIdRef.current === null && prevId.current !== id && prevTypeId.current === typeId ? null : typeId;

  // render

  switch (typeIdRef.current) {
    case "1":
      return <Dashboard1 id={id} typeId={typeId} />;
    case "2":
      return <Dashboard2 id={id} typeId={typeId} />;
    case "3":
      return <Dashboard3 id={id} typeId={typeId} />;
    case "4":
      return <Dashboard4 id={id} typeId={typeId} />;
    case "5":
      return <Dashboard5 id={id} typeId={typeId} />;
    case "6":
      return <Dashboard6 id={id} typeId={typeId} />;
    case "7":
      return <Dashboard7 id={id} typeId={typeId} />;
    case "8":
      return <Dashboard8 id={id} typeId={typeId} />;
    case "9":
      return <Dashboard9 id={id} typeId={typeId} />;
    case "10":
      return <Dashboard10 id={id} typeId={typeId} />;
    case "11":
      return <Dashboard11 id={id} typeId={typeId} />;
    case "12":
      return <Dashboard12 id={id} typeId={typeId} />;
    case "13":
      return <Dashboard13 id={id} typeId={typeId} />;
    case "14":
      return <Dashboard14 id={id} typeId={typeId} />;
    case null:
      return null;
    default:
      return <ErrorPage caption={`Dashboard ${typeId} does not exits`} />;
  }
}

export default Component;
