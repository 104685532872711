import React, { useRef } from "react";
import { DataGrid1 as Grid } from "@kayugasolution/ui";
import { currency, withMemo } from "helper/global";
import { Column } from "devextreme-react/data-grid";

function Component(props) {
  //
  // hooks

  const dataGrid = useRef(null);

  // event handlers

  function onInitialized(e) {
    dataGrid.current = e.component;
  }

  // render

  return (
    <DataGrid
      title={props.title}
      dataSource={props.dataSource}
      multiEditOptions={props.multiEditOptions}
      onInitialized={onInitialized}
      onBackButtonClick={props.onBackButtonClick}
      onLoading={props.onLoading}
      onLoaded={props.edittor}
      multiEditUrl={props.multiEditUrl}
      onToolbarPreparing={props.onToolbarPreparing}
      showActivity={props.showActivity}
    />
  );
}

export default Component;

const DataGrid = withMemo(
  (props) => {
    // render

    function onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        visible: !!props.onBackButtonClick,
        options: {
          hint: "Back",
          type: "back",
          onClick: props.onBackButtonClick,
        },
      });
      props.onToolbarPreparing && props.onToolbarPreparing(e);
    }

    return (
      <Grid
        title={props.title}
        showTitle={props.showTitle}
        dataSource={props.dataSource}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        onToolbarPreparing={onToolbarPreparing}
        onInitialized={props.onInitialized}
      >
        <Column visible={false} dataField={"id"} />
        <Column caption={"AssetId"} dataField={"name"} width={200} />
        <Column caption={"Description"} dataField={"description"} width={400} />
        <Column caption={"Activity Name"} dataField={"activityName"} width={200} visible={!!props.showActivity} />
        <Column caption={"Activity Description"} dataField={"activityDescription"} width={200} visible={!!props.showActivity} />
        <Column caption={"Activity Type"} dataField={"activityType"} width={200} visible={!!props.showActivity} />
        <Column caption={"Location"} dataField={"location"} width={200} visible={false} />
        <Column caption={"Ownership"} dataField={"ownership"} width={150} />
        <Column caption={"Status"} dataField={"status"} width={150} />
        <Column caption={"Class"} dataField={"class"} width={150} />
        <Column caption={"Type"} dataField={"type"} width={150} visible={true} />
        <Column caption={"Size"} dataField={"size"} width={150} visible={true} />
        <Column caption={"Material"} dataField={"material"} width={150} visible={true} />
        <Column caption={"Manufacturer"} dataField={"manufacturer"} width={150} visible={true} />
        <Column caption={"Model"} dataField={"model"} width={150} visible={true} />
        <Column caption={"Count"} dataField={"count"} dataType="number" width={150} />
        <Column caption={"Install Year"} dataType="number" dataField={"installYear"} width={150} />
        <Column caption={"Useful Life"} dataField={"usefulLife"} dataType="number" width={150} />
        <Column caption={"StrategyId"} dataField={"strategy"} width={150} />
        <Column caption={"Replacement / Program Cost"} dataField={"activityCost"} width={150} format={`${currency()}#,###`} />
        <Column caption={"Consumed"} dataField={"consumed"} dataType="number" width={150} format={"0.##"} />
        <Column caption={"Condition"} dataField={"condition"} dataType="number" width={150} format={"0.##"} />
        <Column caption={"R"} dataField={"r"} dataType="number" width={150} format={"0.##"} />
        <Column caption={"Standby"} dataField={"standby"} dataType="number" width={150} />
        <Column caption={"PoF"} dataField={"pof"} dataType="number" width={150} format={"#0.00"} />
        <Column caption={"CoF"} dataField={"cof"} dataType="number" width={150} format={"#0.00"} />
        <Column caption={"Bre"} dataField={"bre"} dataType="number" width={150} format={"#0.##"} />
        <Column caption={"Condition Rating"} dataField={"conditionRating"} width={150} />
        <Column caption={"Risk Rating"} dataField={"riskRating"} width={150} />
        {/* <Column
          type={"buttons"}
          buttons={[
            "edit",
            {
              hint: "Edit",
              icon: "edit",
              visible: true,
              onClick: props.onAssetEditor,
            },
          ]}
          width={125}
          fixed={true}
        /> */}
      </Grid>
    );
  },
  ["dataSource"]
);
