import React from "react";
// import styles from "./styles.module.scss";
import PieChart from "components/piechart";
import { toMillion } from "helper/global";
import { Series, Label, Legend, Font, CommonSeriesSettings } from "devextreme-react/chart";
// import { ChartExpand } from "@kayugasolution/ui";
import ChartExpand from "components/chartExpand";

function Component(props) {
  //
  // event handlers

  function customizeText(e) {
    return `$${toMillion(e.value)} / ${(e.percent * 100).toFixed(2)}%`;
  }

  // render

  function innerDonutRender(pieChart) {
    var count = pieChart
      .getAllSeries()[0]
      .getVisiblePoints()
      .reduce((s, p) => s + p.originalValue, 0);

    if (!count) return null;
    return (
      <svg>
        <text textAnchor="middle" x="0" y="50" style={{ fontSize: 30, fill: "# #adadad" }}>
          <tspan x="100">{`$${toMillion(count)}`}</tspan>
        </text>
      </svg>
    );
  }

  return (
    <ChartExpand title={"Class Cost Distribution"} componentId={2202}>
      <PieChart
        chartId={2202}
        dataSource={props.data}
        type="donut"
        innerRadius={0.65}
        centerRender={innerDonutRender}
        // onDrawn={props.onDrawn}
        visible={props.visible}
        redrawOnResize={false}
      >
        <CommonSeriesSettings>
          <Label visible={true} position="inside" customizeText={customizeText}>
            <Font size={20} />
          </Label>
        </CommonSeriesSettings>
        <Series argumentField="name" valueField="cost" hoverMode="none"></Series>
        <Legend position="inside" orientation="vertical" verticalAlignment="top" horizontalAlignment="left" columnCount={1}>
          <Font size={16} />
        </Legend>
        {/* <AdaptiveLayout height={500} width={500} /> */}
      </PieChart>
    </ChartExpand>
  );
}

export default Component;
