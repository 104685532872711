import React, { useState } from "react";
import styles from "./styles.module.scss";
import { DataGrid1 as Grid } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import Policy from "./policy";
import { withMemo } from "helper/global";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.user}>
        <User onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.policy}>{state.id && <Policy departmentId={state.id} />}</div>
    </div>
  );
}
export default Component;

const User = withMemo((props) => {
  //
  // stores

  const userStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/dbd/department`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/dbd/department`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/dbd/department`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/dbd/department/${id}`, method: "Delete" });
      },
    }),
  };

  // const

  // render

  return (
    <div className={styles.main}>
      <Grid
        dataSource={userStore}
        title={"Department"}
        editingMode={"popup"}
        selection={{ mode: "single" }}
        rowNumbering={true}
        allowDownloading={false}
        onSelectionChanged={props.onSelectionChanged}
      >
        <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
        <Column caption={"Name"} dataField={"name"} allowFiltering={true} width={400}>
          <RequiredRule />
        </Column>
        <Column caption={"Description"} dataField={"description"} width={400} />
        <Column caption={"Notes"} dataField={"notes"} formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }} />
      </Grid>
    </div>
  );
}, []);
