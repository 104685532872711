import React from "react";
import PieChart from "components/piechart";
import { commaFormat } from "helper/global";
import { Series, Label, Legend, Font, CommonSeriesSettings } from "devextreme-react/chart";
import ChartExpand from "components/chartExpand";

function Component(props) {
  //
  // event handlers

  function customizeText(e) {
    return `${commaFormat(e.value)} / ${(e.percent * 100).toFixed(2)}%`;
  }

  // render

  function innerDonutRender(pieChart) {
    var cost = pieChart
      .getAllSeries()[0]
      .getVisiblePoints()
      .reduce((s, p) => s + p.originalValue, 0);

    if (!cost) return null;
    return (
      <svg>
        <text textAnchor="middle" x="0" y="50" style={{ fontSize: 30, fill: "# #adadad" }}>
          <tspan x="100">{`${commaFormat(cost)}`}</tspan>
        </text>
      </svg>
    );
  }

  return (
    <ChartExpand title="Hierarchy Cost Distribution" componentId={2301}>
      <PieChart
        chartId={2301}
        dataSource={props.data}
        type="donut"
        innerRadius={0.65}
        centerRender={innerDonutRender}
        onDrawn={props.onDrawn}
        visible={props.visible}
      >
        <CommonSeriesSettings>
          <Label visible={true} position="inside" customizeText={customizeText}>
            <Font size={20} />
          </Label>
        </CommonSeriesSettings>
        <Series argumentField="name" valueField="count" hoverMode="none"></Series>
        <Legend position="inside" orientation="vertical" verticalAlignment="top" horizontalAlignment="left" columnCount={1}>
          <Font size={16} />
        </Legend>
      </PieChart>
    </ChartExpand>
  );
}

export default Component;
