import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";

export const _loadOrg = (result) => {
  return {
    type: actionTypes.ORG_LOAD,
    ...result,
  };
};

export const loadOrg = () => {
  return async (dispatch) => {
    try {
      let result = await request({ url: `${url}/organizationsetting` });
      dispatch(_loadOrg(result));
    } catch (error) {}

    return "done";
  };
};
