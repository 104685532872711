import React from "react";
import PieChart from "components/piechart";
import { currencyFormat, currency, toMillion } from "helper/global";
import { Series, Label, Legend, Connector, Font } from "devextreme-react/chart";
import ChartExpand from "components/chartExpand";

function Component(props) {
  //
  // render

  function customizePoint(e) {
    return {
      color: e.data.color,
    };
  }

  function customizeText(arg) {
    return currencyFormat(arg.value);
  }

  function donutRender(pieChart) {
    var cost = pieChart
      .getAllSeries()[0]
      .getVisiblePoints()
      .reduce((s, p) => s + p.originalValue, 0);

    if (!cost) return null;
    return (
      <svg>
        <text textAnchor="middle" x="0" y="50" style={{ fontSize: 16, fill: "# #adadad" }}>
          <tspan x="100">{`${currency()}${toMillion(cost)}`}</tspan>
        </text>
      </svg>
    );
  }

  return (
    <ChartExpand title={"Condition Cost Distribution"} componentId={21201}>
      <PieChart
        dataSource={props.data}
        type="donut"
        innerRadius={0.65}
        centerRender={donutRender}
        customizePoint={customizePoint}
        chartId={21201}
        // onPointClick={onPointClick}
      >
        <Series argumentField="rating" valueField={"cost"} hoverMode="none">
          <Label visible={true} position="inside" customizeText={customizeText}>
            <Font size={16} />
          </Label>
        </Series>
        <Legend position="inside" orientation="vertical" verticalAlignment="top" horizontalAlignment="left" columnCount={1}>
          <Font size={16} />
        </Legend>
      </PieChart>
    </ChartExpand>
  );
}

export default Component;
