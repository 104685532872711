import React, { useState, useContext, useEffect } from "react";
import Hierarchy from "components/hierarchy";
import { LoadingIndicator2 as LoadingIndicator } from "@kayugasolution/ui";
import { request } from "helper/http-client";
import { url } from "config.js";
import HierarchyContext from "components/hierarchy/context";
import CostProfile from "./costprofile";

function Component(props) {
  // render

  return (
    <Hierarchy id={props.id} showCount={true} showFilter={false}>
      <CostProfile />
    </Hierarchy>
  );
}

export default Component;
