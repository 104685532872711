import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { notify } from "helper/global";

export const _loadUser = (result) => {
  return {
    type: actionTypes.USER_LOAD,
    ...result,
  };
};

export const loadUser = (url) => {
  return async (dispatch) => {
    try {
      let result = await request({ url: `${url}/usersetting` });
      dispatch(_loadUser(result));
    } catch (error) {
      notify("Unable to load user settings", "error");
      throw error;
    }
    return "done";
  };
};
