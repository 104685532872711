import React, { useState, useEffect, useContext, useRef } from "react";
import { request } from "helper/http-client";
import { url } from "config.js";
import Chart from "components/chart";
import HierarchyContext from "components/hierarchy/context";
import {
  CommonSeriesSettings,
  CommonAxisSettings,
  Series,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  MinorTick,
  Format,
  Tooltip,
  ZoomAndPan,
  Font,
} from "devextreme-react/chart";
import ChartExpand from "components/chartExpand";
import { LoadingIndicator2 as LoadingIndicator } from "@kayugasolution/ui";
import Hierarchy from "components/hierarchy";

function Component(props) {
  // render

  return (
    <Hierarchy
      id={props.id}
      showCount={true}
      showFilter={true}
      filterFields={["Class", "Type", "Size", "Material", "Manufacturer", "Status", "Ownership", "Group", "Tag", "Risk", "Condition", "CoF"]}
      filterType={1}
    >
      <Dashboard />
    </Hierarchy>
  );
}

export default Component;

function Dashboard() {
  //
  //hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  const [state, setState] = useState(null);

  useEffect(() => {
    setState(null);
    (async () => {
      const result = await request({
        url: `${url}/dbd/6/usefullife`,
        params: { ids: selectedRowsData.map((i) => i.id), filterExpression: filter },
      });
      setState(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, filter]);

  function customizeText(e) {
    return { text: `Hierarchy: ${e.argument}, Avg. UsefulLife: ${e.value} Years` };
  }

  // render

  return state ? (
    <ChartExpand componentId={2601}>
      <Chart chartId={2601} dataSource={state}>
        <CommonSeriesSettings argumentField="name" type="bar" barPadding={0.5} />
        <CommonAxisSettings>
          <Label>
            <Font size={14} />
          </Label>
        </CommonAxisSettings>
        <Tooltip enabled={true} customizeTooltip={customizeText} />
        <ArgumentAxis name="" allowDecimals={false} minorTickCount={4}>
          <MinorTick visible={true} />
          <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
            <Format type="decimal" />
          </Label>
        </ArgumentAxis>
        <ValueAxis name="" title={"Average Remaining UsefulLife (Years)"}></ValueAxis>
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
        <Series key={"id"} valueField={"avgUsefulLife"} name={"avgUsefulLife"} />
        <Legend visible={false}>
          <Font size={14} />
        </Legend>
      </Chart>
    </ChartExpand>
  ) : (
    <LoadingIndicator />
  );
}
