import React from "react";
import styles from "./styles.module.scss";
import { DataGrid1 as Grid } from "@kayugasolution/ui";
import { Column } from "devextreme-react/data-grid";
import { currency } from "helper/global";

function Component(props) {
  return (
    <div className={styles.main}>
      <Grid
        title={"Group"}
        showTitle={false}
        allowAdding={false}
        allowUpdating={false}
        allowDeleting={false}
        allowDownloading={true}
        allowFiltering={false}
        allowSorting={false}
        dataSource={props.data}
      >
        <Column visible={false} dataField={"id"} />
        <Column caption={"Risk Level"} dataField={"rating"} />
        <Column caption={"No. of Asset"} dataField={"count"} format={`#,###`} />
        <Column caption={"% by Number"} dataField={"percentCount"} format={`##.0'%'`} />
        <Column caption={"Value of Asset"} dataField={"cost"} format={`${currency()}#,###`} />
        <Column caption={"% by Value"} dataField={"percentCost"} format={`##.0'%'`} />
      </Grid>
    </div>
  );
}

export default Component;
