import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";

export const _loadUIComponent = (result) => {
  return {
    type: actionTypes.UICOMPONENT_LOAD,
    result: result,
  };
};

export const loadUIComponent = () => {
  return async (dispatch) => {
    try {
      let result = await request({ url: `${url}/uicomponent/settings` });
      dispatch(_loadUIComponent(result));
    } catch (error) {}

    return "done";
  };
};
