import React, { useState, useContext } from "react";
import styles from "./styles.module.scss";
import { withMemo, currency } from "helper/global";
import { request } from "helper/http-client";
import { url } from "config.js";
import { DataGrid1 as Grid } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { Column } from "devextreme-react/data-grid";
import HierarchyContext from "components/hierarchy/context";

function Component() {
  //
  // stores

  const store = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!state.id) return Promise.resolve([]);
        return request({
          url: `${url}/dbd/8`,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            classId: state.id,
            filterExpression: filter,
          },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  return (
    <div className={styles.main}>
      <div className={styles.class}>
        <Class onSelectionChanged={onSelectionChanged} />
      </div>
      <div className={styles.type}>
        <Grid
          title={"Type"}
          showTitle={true}
          allowAdding={false}
          allowUpdating={false}
          allowDeleting={false}
          allowDownloading={true}
          allowFiltering={false}
          allowSorting={false}
          dataSource={store}
        >
          <Column visible={false} dataField={"id"} />
          <Column caption={"Type"} dataField={"type"} />
          <Column caption={"No. of Asset"} dataField={"count"} format={`#,###`} />
          <Column caption={"% by Number"} dataField={"percentCount"} format={`##.0'%'`} />
          <Column caption={"Value of Asset"} dataField={"cost"} format={`${currency()}#,###`} />
          <Column caption={"% by Value"} dataField={"percentCost"} format={`##.0'%'`} />
        </Grid>
      </div>
    </div>
  );
}

export default Component;

const Class = withMemo((props) => {
  const classStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/class`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/class/${id}` });
      },
    }),
  };

  return (
    <Grid
      title={"Class"}
      dataSource={classStore}
      selection={{ mode: "single" }}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={false}
      onSelectionChanged={props.onSelectionChanged}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} allowFiltering={true}></Column>
    </Grid>
  );
}, []);
