import React, { useState, useRef } from "react";
import styles from "./styles.module.scss";
import CustomStore from "devextreme/data/custom_store";
import { DataGrid2 as Grid } from "@kayugasolution/ui";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";
import Editor from "./editor";

function Component() {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);
  const gridRef = useRef(null);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  function onSaving() {
    setState(initialState);
  }

  function onInitialized(e) {
    gridRef.current = e.component;
  }

  function onRefresh() {
    //var id = state.id;
    //setState(initialState);
    //gridRef.current.clearSelection();
    gridRef.current.refresh();
    //gridRef.current.selectRows([id], false);
  }

  // render

  return (
    <div className={styles.main}>
      <div className={styles.grid}>
        <UIComponent onSelectionChanged={onSelectionChanged} onSaving={onSaving} onInitialized={onInitialized} />
      </div>
      <div className={styles.editor}>{state.id && <Editor data={state} onRefresh={onRefresh} />}</div>
    </div>
  );
}

const UIComponent = React.memo(
  //
  // store

  (props) => {
    const uiComponentStore = {
      store: new CustomStore({
        key: "id",
        load: (loadOptions) => {
          return request({ url: `${url}/uicomponent`, loadOptions: loadOptions });
        },
        insert: (data) => {
          return request({ url: `${url}/uicomponent`, method: "Post", data: data });
        },
        update: (_, data) => {
          return request({ url: `${url}/uicomponent`, method: "Put", data: data });
        },
        remove: (id) => {
          return request({ url: `${url}/uicomponent/${id}`, method: "Delete" });
        },
      }),
    };

    const styles = [
      {
        id: 0,
        name: "Screen",
      },
      {
        id: 1,
        name: "Export",
      },
    ];

    // render

    return (
      <Grid
        title={"Chart"}
        allowDownloading={false}
        dataSource={uiComponentStore}
        onSelectionChanged={props.onSelectionChanged}
        onSaving={props.onSaving}
        onInitialized={props.onInitialized}
      >
        <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
        <Column caption={"Description"} dataField={"description"} />
        <Column caption={"ComponentId"} dataField={"componentId"} dataType="number" visible={false}>
          <RequiredRule />
        </Column>
        <Column caption={"Style"} dataField={"styleId"} width={200} visible={false}>
          <Lookup dataSource={styles} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column visible={false} formItem={{ itemType: "empty" }} />
        <Column
          caption={"Settings"}
          dataField={"settings"}
          visible={false}
          allowFiltering={true}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 300 } }}
        />
      </Grid>
    );
  },
  () => {
    return true;
  }
);

export default Component;
