import React from "react";
import Hierarchy from "components/hierarchy";
import Class from "./class";

function Component(props) {
  // render

  return (
    <Hierarchy
      id={props.id}
      showCount={true}
      showFilter={true}
      filterFields={["Class", "Type", "Size", "Material", "Manufacturer", "Status", "Ownership", "Group", "Tag", "Risk", "Condition", "CoF"]}
      filterType={1}
    >
      <Class />
    </Hierarchy>
  );
}

export default Component;
