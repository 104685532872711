import React, { useRef } from "react";
import CustomStore from "devextreme/data/custom_store";
import { DataGrid1 as Grid } from "@kayugasolution/ui";
import { request } from "helper/http-client";
import { url } from "config.js";
import { ProgressPanel1 as ProgressPanel } from "@kayugasolution/ui";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { Column, Lookup } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";

function Component(props) {
  const strategyStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        if (!props.groupId) return Promise.resolve({ data: [] });
        return exporting.current
          ? request({ url: `${url}/dbd/menu/download`, loadOptions: loadOptions })
          : request({ url: `${url}/dbd/menu/group/${props.groupId}`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/dbd/menu`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/dbd/menu`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/dbd/menu/${id}`, method: "Delete" });
      },
    }),
  };

  const groupStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/dbd/group`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/dbd/group/${id}` });
      },
    }),
  };

  const nodeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/hierarchy`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/hierarchy/${id}` });
      },
    }),
    paginate: true,
    pageSize: 20,
  };

  const typeStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/dbd/type`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/dbd/type/${id}` });
      },
    }),
  };

  // hooks

  const exporting = useRef(false);

  // event handlers

  async function onUploaded(fileId) {
    props.upload({ name: "UPLOAD_MENU", url: `${url}/dbd/menu/upload/file/${fileId}` });
  }

  function onExporting(e) {
    e.component.columnOption("id", "visible", true);
    e.component.columnOption("id", "sortOrder", "asc");
    e.component.columnOption("operation", "visible", true);
    e.component.columnOption("groupId", "visible", true);
    exporting.current = true;
  }

  function onExported(e) {
    e.component.columnOption("id", "visible", false);
    e.component.columnOption("id", "sortOrder", "desc");
    e.component.columnOption("operation", "visible", false);
    e.component.columnOption("groupId", "visible", false);

    exporting.current = false;
  }

  return (
    <ProgressPanel name={"UPLOAD_MENU"}>
      <Grid
        title={"Dashboard"}
        dataSource={strategyStore}
        onUploaded={onUploaded}
        onExported={onExported}
        onExporting={onExporting}
        allowExpanding={false}
        onInitNewRow={(e) => {
          e.data.groupId = props.groupId || null;
        }}
      >
        <Column visible={false} dataField={"id"} formItem={{ visible: false }} />
        <Column
          caption={"Operation"}
          dataField={"operation"}
          calculateCellValue={() => "Update"}
          visible={false}
          formItem={{ visible: false }}
        />
        <Column caption={"Name"} dataField={"name"} width={200} />
        <Column caption={"Description"} dataField={"description"} width={400} />
        <Column caption={"Order"} dataField={"order"} width={150} sortOrder={"asc"} />
        <Column caption={"Type"} dataField={"typeId"} alculateDisplayValue="type" width={150}>
          <Lookup dataSource={typeStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column
          caption={"Group"}
          dataField={"groupId"}
          visible={false}
          calculateDisplayValue="group"
          width={150}
          formItem={{ visible: true }}
        >
          <Lookup dataSource={groupStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        <Column caption={"Node"} dataField={"nodeId"} visible={true} calculateDisplayValue="node" width={150}>
          <Lookup dataSource={nodeStore} valueExpr={"id"} displayExpr="name" />
          <RequiredRule />
        </Column>
        {/* <Column visible={false} formItem={{ itemType: "empty" }} /> */}
        <Column
          caption={"Notes"}
          dataField={"notes"}
          formItem={{ colSpan: 2, editorType: "dxTextArea", editorOptions: { height: 75 } }}
          width={400}
        />
      </Grid>
    </ProgressPanel>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    upload: (options) => dispatch(actions.progress(options)),
  };
};

export default connect(null, mapDispatchToProps)(Component);
