import React, { useState } from "react";
import styles from "./styles.module.scss";
import Hierarchy from "components/hierarchy";
import FundsDistribution from "./fundsDistribution";
import FundProfile from "./fundprofile";
import { withMemo } from "helper/global";
import { DataGrid1 as Grid } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column } from "devextreme-react/data-grid";

function Component(props) {
  //
  // hooks

  const initialState = { id: null };
  const [state, setState] = useState(initialState);

  // event handlers

  function onSelectionChanged(e) {
    setState(e.selectedRowsData[0] || initialState);
  }

  // render

  return (
    <Hierarchy
      id={props.id}
      showCount={true}
      showFilter={true}
      filterFields={[
        "Class",
        "Type",
        "Size",
        "Material",
        "Manufacturer",
        "Status",
        "Ownership",
        "Group",
        "Tag",
        "Risk",
        "Condition",
        "CoF",
      ]}
      filterType={1}
    >
      <div className={styles.main}>
        <div className={styles.grid}>
          <Fund onSelectionChanged={onSelectionChanged} />
        </div>
        <div className={styles.container}>
          <div className={styles.chart1}>{<FundsDistribution />}</div>
          <div className={styles.chart2}>{state.id && <FundProfile fundId={state.id} />}</div>
        </div>
      </div>
    </Hierarchy>
  );
}

export default Component;

const Fund = withMemo((props) => {
  //
  // stores

  const userStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/fund`, loadOptions: loadOptions });
      },
    }),
  };

  // const

  // render

  return (
    <Grid
      dataSource={userStore}
      title={"Fund"}
      selection={{ mode: "single" }}
      rowNumbering={true}
      allowDownloading={false}
      allowEditing={false}
      onSelectionChanged={props.onSelectionChanged}
    >
      <Column visible={false} dataField={"id"} sortOrder={"desc"} formItem={{ visible: false }} />
      <Column caption={"Name"} dataField={"name"} allowFiltering={true} width={400} />
    </Grid>
  );
}, []);
