import React from "react";
import Toolbar, { Item } from "devextreme-react/toolbar";
import { connect } from "react-redux";
import UserPanel from "../user-panel/user-panel";
import "./header.scss";

function Header(props) {
  //
  // const
  const { toggleMenu } = props;

  // render

  function renderLabel() {
    return <div className="klient-layout-header-organization">{props.organization}</div>;
  }

  return (
    <header className={"klient-layout-header"}>
      <Toolbar className={"toolbar"}>
        <Item
          location={"before"}
          widget={"dxButton"}
          cssClass={"menu"}
          options={{
            icon: "fas fa-bars",
            stylingMode: "text",
            onClick: toggleMenu,
          }}
        />
        <Item location={"center"} locateInMenu="never">
          <div className="klient-layout-header-logo">
            <img className="klient-layout-header-logo" alt="/" src={props.logoUrl} height={props.logoHeight} />
          </div>
        </Item>
        <Item location="center" locateInMenu="never" render={renderLabel} />
        <Item location={"after"} cssClass={"user"}>
          <UserPanel />
        </Item>
      </Toolbar>
    </header>
  );
}

function mapStateToProps(state) {
  return {
    organization: "",
    logoUrl: state.org.headerLogoUrl,
    logoHeight: state.org.headerLogoHeight,
    hideTitle: false,
  };
}

export default connect(mapStateToProps, null)(Header);
