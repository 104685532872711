import React, { useRef, useEffect } from "react";
import TreeView from "devextreme-react/tree-view";
import "./layout.scss";
import styles from "./layout.module.scss";
import * as events from "devextreme/events";
import Drawer from "devextreme-react/drawer";
import { Template } from "devextreme-react/core/template";
import { useLocation, useHistory, useRouteMatch } from "react-router-dom";
import Header from "./header/header";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";

function Component(props) {
  // hooks

  const treeView = useRef();
  const div = useRef();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  // event handlers

  function toggleMenu() {
    if (props.menuOpened) treeView.current.collapseAll();
    props.toggleMenu();
  }

  function selectedItem() {
    var items = [];

    props.navigation.forEach((i) => {
      items.push(...i.items);
    });

    let path2 = location.pathname.split("/")[2];
    if (path2 === undefined) return props.path;

    let path3 = location.pathname.split("/")[3];
    if (!path3) return items[0];

    let item = null;

    item = items.find((i) => i.path.includes(`/${path2}/${path3}`)) || items[0];

    return (item && item.path) || props.path;
  }

  function onItemClick(e) {
    if (props.menuOpened && e.itemData.path) {
      history.push(`${match.path}${e.itemData.path}`);
    }
  }

  function onMenu(e) {
    if (!props.menuOpened) {
      props.toggleMenu();
    }
  }

  // functions

  function onSelectionChanged(e) {
    const nodeClass = "dx-treeview-node";
    const selectedClass = "dx-state-selected";
    const leafNodeClass = "dx-treeview-node-is-leaf";
    const element = e.element;

    const rootNodes = element.querySelectorAll(`.${nodeClass}:not(.${leafNodeClass})`);

    Array.from(rootNodes).forEach((node) => {
      node.classList.remove(selectedClass);
    });

    let selectedNode = element.querySelector(`.${nodeClass}.${selectedClass}`);

    if (selectedNode) {
      while (selectedNode && selectedNode.parentElement) {
        if (selectedNode.classList.contains(nodeClass)) {
          selectedNode.classList.add(selectedClass);
        }

        selectedNode = selectedNode.parentElement;
      }
    }
    treeView.current.selectItem(selectedItem());
  }

  // render

  function MyTreeView() {
    useEffect(() => {
      events.on(div.current, "dxclick", onMenu);

      return () => {
        events.off(div.current, "dxclick");
      };
    }, []);

    return (
      <div ref={div} className={`klient_layout_side_treeview ${styles.tree}`}>
        <div className={styles.tree_item}>
          <TreeView
            items={props.navigation}
            selectionMode={"single"}
            keyExpr={"path"}
            searchMode={"contains"}
            expandEvent={"click"}
            width={"100%"}
            onSelectionChanged={onSelectionChanged}
            onItemClick={onItemClick}
            onInitialized={(e) => {
              treeView.current = e.component;
              treeView.current.option("deferRendering", false);
            }}
            onContentReady={onSelectionChanged}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.main}>
      <div className={styles.header}>
        <Header menuToggleEnabled toggleMenu={toggleMenu} />
      </div>
      <Drawer
        className={"klient_layout_side"}
        opened={props.menuOpened}
        openedStateMode={"shrink"}
        revealMode={"expand"}
        shading={false}
        minSize={60}
        position={"before"}
        maxSize={300}
        template={"menu"}
      >
        <div className={styles.container}>{props.children}</div>
        <Template name="menu">
          <MyTreeView></MyTreeView>
        </Template>
      </Drawer>
      <div className={styles.footer}>
        <div className={styles.footer_left}></div>
        <div className={styles.footer_right}>IRIS Apps by Kayuga {`(${process.env.REACT_APP_DOC_VERSION})`}</div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    menuOpened: state.ui.menuOpened,
    user: null,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMenu: () => dispatch(actions.toggleMenu()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
