import React, { useEffect, useState } from "react";
import Layout from "layout/layout";
import { Switch, Route, withRouter, useLocation, useHistory, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "redux/actions/index";
import { url } from "config.js";
import { withMemo } from "helper/global";
import { LoadingIndicator1, ErrorPage } from "@kayugasolution/ui";
import Settings from "./admin/settings";
import Access from "./admin/access1";
import { request } from "helper/http-client";
import Dashboards from "./dashboards";
import { registerPalette } from "devextreme/viz/palette";

function Component(props) {
  //
  // hooks

  const [state, setState] = useState(null);

  let query = useQuery();
  const history = useHistory();

  useEffect(() => {
    let id = query.get("id");
    let jwt = query.get("jwt");
    props.authenticate(`${url}/dbd`, { id, jwt });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.isAuthenticated === true) {
      if (query.has("id")) {
        query.delete("id");
        query.delete("jwt");
        history.replace({
          search: query.toString(),
        });
      }
      props.palettes.forEach((palette) => {
        registerPalette(palette.name, palette.colors);
      });

      (async () => {
        const groups = await request({ url: `${url}/dbd/group/user` });
        const menus = await request({ url: `${url}/dbd/menu/user` });
        setState({ groups, menus });
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isAuthenticated]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  if (props.isAuthenticated === false) {
    return <ErrorPage caption={"Unauthorized Access"} />;
  }

  if (props.isAuthenticated === null || state === null) {
    return <LoadingIndicator1 />;
  }

  return (
    props.isAuthenticated === true &&
    state && (
      <Switch>
        <Route
          path="/dbd"
          render={() => {
            return <MemorizedLayout isAdmin={props.isAdmin} treelist={state} />;
          }}
        />
        <Redirect to="/dbd" />
      </Switch>
    )
  );
}

const MemorizedLayout = withMemo((props) => {
  //
  // functions

  function setNavigation() {
    var navigation = [];
    for (let i of props.treelist.groups) {
      var menus = props.treelist.menus.filter((j) => j.groupId === i.id);
      var items = [];
      for (let j of menus) {
        items.push({
          text: j.name,
          path: `/dashboard/${j.id}/${j.typeId}`,
          icon: "fad fa-chart-bar",
        });
      }
      navigation.push({
        text: i.name,
        icon: "fad fa-user-chart",
        items: items,
      });
    }
    navigation.push({
      text: "Admin",
      icon: "fad fa-users-crown",
      items: [
        {
          text: "Settings",
          path: "/admin/settings",
          icon: "fad fa-cog",
        },
        {
          text: "Access Control",
          path: "/admin/access",
          icon: "fad fa-user-lock",
        },
      ],
    });
    let admin = navigation.find((i) => i.text === "Admin");
    admin.visible = props.isAdmin;
    return navigation;
  }

  var navigation = setNavigation();

  function getMenu(nav) {
    console.log(nav);
    var menu = nav.find((i) => i.items.length > 0);
    console.log(menu);
    return menu ? `/dbd${menu.items[0].path}` : `/dbd/admin/settings`;
  }

  // render

  return (
    <Layout navigation={navigation}>
      <Switch>
        <Route path={"/dbd/dashboard/:id/:typeId"} component={Dashboards} />
        <Route path={"/dbd/admin/access"} component={Access} />
        <Route path={"/dbd/admin/settings"} component={Settings} />
        <Redirect from="/dbd" to={getMenu(navigation)} />
      </Switch>
    </Layout>
  );
}, []);

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAdmin: state.user.roleId === 1,
    palettes: state.palette.palettes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authenticate: (url, app) => dispatch(actions.authenticate(url, app)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Component));

// function Dashboards() {
//   let { id, typeId } = useParams();
//   switch (typeId) {
//     case "1":
//       return <Dashboard1 id={id} />;
//     default:
//       return <ErrorPage caption={`Dashboard ${typeId} does not exits`} />;
//   }
// }
