import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { url } from "config.js";

export const _loadPalette = (result) => {
  return {
    type: actionTypes.PALETTE_LOAD,
    ...result,
  };
};

export const loadPalette = () => {
  return async (dispatch) => {
    try {
      let result = await request({ url: `${url}/chartpalette/settings` });
      dispatch(_loadPalette(result));
    } catch (error) {}

    return "done";
  };
};
