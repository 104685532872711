import React, { useState, useEffect, useContext } from "react";
import { request } from "helper/http-client";
import { url } from "config.js";
import Chart from "components/chart";
import { currencyFormat } from "helper/global";
import HierarchyContext from "components/hierarchy/context";
import {
  CommonSeriesSettings,
  CommonAxisSettings,
  Series,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Label,
  MinorTick,
  Format,
  Tooltip,
  ZoomAndPan,
  Font,
} from "devextreme-react/chart";
import ChartExpand from "components/chartExpand";
import { LoadingIndicator2 as LoadingIndicator } from "@kayugasolution/ui";
import Hierarchy from "components/hierarchy";

function Component(props) {
  // render

  return (
    <Hierarchy
      id={props.id}
      showCount={true}
      showFilter={true}
      filterFields={["Class", "Type", "Size", "Material", "Manufacturer", "Status", "Ownership", "Group", "Tag", "Risk", "Condition", "CoF"]}
      filterType={1}
    >
      <Dashboard />
    </Hierarchy>
  );
}

export default Component;

function Dashboard(props) {
  //
  //hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  const [state, setState] = useState(null);

  useEffect(() => {
    setState(null);
    (async () => {
      const result = await request({
        url: `${url}/dbd/5/installation`,
        params: { ids: selectedRowsData.map((i) => i.id), filterExpression: filter },
      });
      setState(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, filter]);

  function customizeText(e) {
    return { text: `${e.seriesName} - Year: ${e.argument}, Value: ${currencyFormat(e.value)}` };
  }

  // render

  function seriesRender() {
    var series = [];
    state.folders.forEach((i) => {
      var valueField = `${i.name}`;
      series.push(<Series key={valueField} valueField={valueField} name={valueField} />);
    });
    return series;
  }

  return state ? (
    <ChartExpand componentId={2501}>
      <Chart chartId={2501} dataSource={state.profile}>
        <CommonSeriesSettings argumentField="year" type="stackedBar" />
        <CommonAxisSettings>
          <Label>
            <Font size={14} />
          </Label>
        </CommonAxisSettings>
        <Tooltip enabled={true} customizeTooltip={customizeText} />
        <ArgumentAxis name="" allowDecimals={false} minorTickCount={4}>
          <MinorTick visible={true} />
          <Label overlappingBehavior={"none"} wordWrap={true} textOverflow="ellipsis">
            <Format type="decimal" />
          </Label>
        </ArgumentAxis>
        <ValueAxis name="" title={"$"}></ValueAxis>
        <ZoomAndPan argumentAxis="both" valueAxis="both" />
        <Legend position="outside" orientation="vertical" verticalAlignment="top" horizontalAlignment="right">
          <Font size={14} />
        </Legend>
        {seriesRender()}
      </Chart>
    </ChartExpand>
  ) : (
    <LoadingIndicator />
  );
}
