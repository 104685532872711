import React from "react";
import { ChartExpand } from "@kayugasolution/ui";
import { connect } from "react-redux";

function Component(props) {
  //
  // const

  const uicomponent = props.uicomponent;
  var screen = null;
  var exp = null;

  if (uicomponent) {
    screen = uicomponent.find((i) => i.componentId === props.componentId && i.styleId === 0);
    exp = uicomponent.find((i) => i.componentId === props.componentId && i.styleId === 1);
  }

  if (screen && screen.settings) {
    screen = JSON.parse(screen.settings);
  }

  if (exp && exp.settings) {
    exp = JSON.parse(exp.settings);
  } else {
    exp = {
      commonSeriesSettings: {
        label: { font: { color: "black" } },
      },
      commonAxisSettings: {
        label: { font: { color: "black" } },
        title: { font: { color: "black" } },
      },
      legend: {
        position: "outside",
        orientation: "center",
        verticalAlignment: "bottom",
        horizontalAlignment: "center",
        columnCount: 0,
        rowCount: 0,
        hoverMode: "none",
        font: { color: "black" },
      },
      margin: { top: 10 },
      title: { text: "none" },
    };
  }

  // render

  return (
    <ChartExpand {...props} configs={{ screen: screen, export: exp }}>
      {props.children}
    </ChartExpand>
  );
}

const mapStateToProps = (state) => {
  return {
    uicomponent: state.uicomponent,
  };
};

export default connect(mapStateToProps, null)(Component);
