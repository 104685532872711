import * as actionTypes from "./actionTypes";
import { request } from "helper/http-client";
import { loadUser } from "./user";
import { loadOrg } from "./org";
import { loadPalette } from "./palette";
import { loadScenario } from "./scenario";
import { loadUIComponent } from "./uicomponent";

const _authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

const _authEnd = (auth) => {
  return {
    type: actionTypes.AUTH_END,
    payload: auth,
  };
};

// public functions

export const authenticate = (url, app) => {
  return async (dispatch) => {
    dispatch(_authStart());
    if (app.id !== null && app.jwt !== null) sessionStorage.setItem("_iris_addon", JSON.stringify(app));
    var irisApp = app.id === null ? JSON.parse(sessionStorage.getItem("_iris_addon")) || null : { ...app };
    var auth = false;
    try {
      auth = await request({ url: `${url}/auth/${irisApp.id}/${irisApp.jwt}`, method: "Post" });
      if (auth)
        await Promise.all([
          dispatch(loadUser(url)),
          dispatch(loadOrg()),
          dispatch(loadScenario()),
          dispatch(loadPalette()),
          dispatch(loadUIComponent()),
        ]);
    } catch {
      auth = false;
    } finally {
      dispatch(_authEnd(auth));
    }
    return "done";
  };
};
