import React from "react";
import styles from "./styles.module.scss";

function Component(props) {
  return (
    <div className={styles.main}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.container}>{props.children}</div>
      {/* <div className={styles.note}>{props.note}</div> */}
    </div>
  );
}

export default Component;
