import React, { useContext } from "react";
import Hierarchy from "components/hierarchy";
import { request } from "helper/http-client";
import { url } from "config.js";
import HierarchyContext from "components/hierarchy/context";
import { DataGrid1 as Grid } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { Column } from "devextreme-react/data-grid";
import { currency } from "helper/global";

function Component(props) {
  // render

  return (
    <Hierarchy
      id={props.id}
      showCount={true}
      showFilter={true}
      filterFields={["Class", "Type", "Size", "Material", "Manufacturer", "Status", "Ownership", "Group", "Tag", "Risk", "Condition", "CoF"]}
      filterType={1}
    >
      <Dashboard />
    </Hierarchy>
  );
}

export default Component;

function Dashboard() {
  //
  //hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  // store

  const store = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({
          url: `${url}/dbd/7`,
          params: {
            ids: selectedRowsData.map((i) => i.id),
            filterExpression: filter,
          },
          loadOptions: loadOptions,
        });
      },
    }),
  };

  // render

  return (
    <Grid
      title={"Group"}
      showTitle={false}
      allowAdding={false}
      allowUpdating={false}
      allowDeleting={false}
      allowDownloading={true}
      allowFiltering={false}
      allowSorting={false}
      dataSource={store}
    >
      <Column visible={true} dataField={"id"} />
      <Column caption={"Condition"} dataField={"rating"} />
      <Column caption={"No. of Asset"} dataField={"count"} format={`#,###`} />
      <Column caption={"% by Number"} dataField={"percentCount"} format={`##.0'%'`} />
      <Column caption={"Value of Asset"} dataField={"cost"} format={`${currency()}#,###`} />
      <Column caption={"% by Value"} dataField={"percentCost"} format={`##.0'%'`} />
    </Grid>
  );
}
