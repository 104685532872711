import React, { useState, createRef } from "react";
import styles from "./styles.module.scss";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { useScreenshot, createFileName } from "use-react-screenshot";

function Component(props) {
  //
  //hooks

  const ref = createRef(null);
  const [, takeScreenShot] = useScreenshot({
    type: "image/png",
    quality: 1.0,
  });

  const [state, setState] = useState(false);

  // functions

  const download = (image, { name = "chart", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  // render

  const injectPropsToAllChildren = (children, newProps) => {
    const newChildren = React.Children.toArray(children).map((child) => {
      const childzFurtherChildren = child.props.children ? injectPropsToAllChildren(child.props.children, newProps) : undefined;

      return childzFurtherChildren ? React.cloneElement(child, newProps, childzFurtherChildren) : React.cloneElement(child, newProps);
    });
    return newChildren;
  };

  return (
    <>
      <div className={styles.main}>
        <div className={styles.toolbar}>
          <div className={styles.toolbar_center}>{props.title}</div>
          <div className={styles.toolbar_right}>
            <Button
              type={"normal"}
              stylingMode="text"
              width={25}
              onClick={() => {
                setState(true);
              }}
            >
              <div className={styles.button}>
                <div className={styles.button_icon}>
                  <FontAwesomeIcon icon={faExpandAlt} />
                </div>
              </div>
            </Button>
          </div>
        </div>
        <div className={styles.body}>{injectPropsToAllChildren(props.children, { expanded: "false" })}</div>
      </div>
      {state && (
        <Popup visible={state} showCloseButton={true} dragEnabled={true} closeOnOutsideClick={false} showTitle={false} resizeEnabled={true}>
          <ToolbarItem
            widget="dxButton"
            toolbar="top"
            location="after"
            options={{
              icon: "export",
              onClick: downloadScreenshot,
            }}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="top"
            location="after"
            options={{
              icon: "close",
              onClick: () => {
                setState(false);
              },
            }}
          />
          <div ref={ref} className={styles.popup}>
            {/* {React.cloneElement(props.children, { popup: true })} */}
            {injectPropsToAllChildren(props.children, { expanded: "true" })}
          </div>
        </Popup>
      )}
    </>
  );
}

export default Component;
