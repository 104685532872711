import React from "react";
import { DataGrid1 as Grid } from "@kayugasolution/ui";
import CustomStore from "devextreme/data/custom_store";
import { request } from "helper/http-client";
import { url } from "config.js";
import { Column, Lookup,  } from "devextreme-react/data-grid";
import { RequiredRule } from "devextreme-react/validator";

function Component() {
  //
  // stores

  const userStore = {
    store: new CustomStore({
      key: "id",
      load: (loadOptions) => {
        return request({ url: `${url}/dbd/user`, loadOptions: loadOptions });
      },
      insert: (data) => {
        return request({ url: `${url}/dbd/user`, method: "Post", data: data });
      },
      update: (_, data) => {
        return request({ url: `${url}/dbd/user`, method: "Put", data: data });
      },
      remove: (id) => {
        return request({ url: `${url}/dbd/user/${id}`, method: "Delete" });
      },
    }),
  };

  const userLookupStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/dbd/user/lookup`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/dbd/user/lookup/${id}` });
      },
    }),
  };

  const departmentStore = {
    store: new CustomStore({
      key: "id",
      useDefaultSearch: true,
      load: (loadOptions) => {
        return request({ url: `${url}/dbd/department`, loadOptions: loadOptions });
      },
      byKey: function (id) {
        return request({ url: `${url}/dbd/department/${id}` });
      },
    }),
  };

  // const

  const roles = [
    { id: 0, name: "User" },
    { id: 1, name: "Admin" },
  ];

  // render

  return (
    <Grid dataSource={userStore} rowNumbering={true} allowDownloading={false}>
      <Column caption={"UserName"} dataField={"id"} sortOrder={"desc"}>
        <Lookup dataSource={userLookupStore} valueExpr={"id"} displayExpr="userName" />
        <RequiredRule />
      </Column>
      <Column caption={"Role"} dataField={"roleId"} width={200}>
        <Lookup dataSource={roles} valueExpr={"id"} displayExpr="name" />
        <RequiredRule />
      </Column>
      <Column caption={"Department"} dataField={"departmentId"} width={200} calculateDisplayValue={"department"}>
        <Lookup dataSource={departmentStore} valueExpr={"id"} displayExpr="name" />
        <RequiredRule />
      </Column>
    </Grid>
  );
}

export default Component;
