import React, { useState, useContext, useEffect } from "react";
import styles from "./styles.module.scss";
import Hierarchy from "components/hierarchy";
import { LoadingIndicator2 as LoadingIndicator } from "@kayugasolution/ui";
import { request } from "helper/http-client";
import { url } from "config.js";
import ClassDistribution from "./class-dist";
import HierarchyDistribution from "./hierarchy-dist";
import HierarchyContext from "components/hierarchy/context";

function Component(props) {
  // render

  return (
    <Hierarchy
      id={props.id}
      showCount={true}
      showFilter={true}
      filterFields={["Class", "Type", "Size", "Material", "Manufacturer", "Status", "Ownership", "Group", "Tag", "Risk", "Condition", "CoF"]}
      filterType={1}
    >
      <Dashboard />
    </Hierarchy>
  );
}

export default Component;

function Dashboard(props) {
  //
  //hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  const [state, setState] = useState(null);

  useEffect(() => {
    setState(null);
    (async () => {
      const result = await request({
        url: `${url}/dbd/3/state`,
        params: { ids: selectedRowsData.map((i) => i.id), filterExpression: filter },
      });
      setState(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, filter]);

  // render

  return state ? (
    <div className={styles.main}>
      <div className={styles.chart1}>
        <HierarchyDistribution data={state.hierarchyDistribution} />
      </div>
      <div className={styles.chart2}>
        <ClassDistribution data={state.classDistribution} />
      </div>
    </div>
  ) : (
    <LoadingIndicator />
  );
}
