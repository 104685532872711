import * as actionTypes from "../actions/actionTypes";

const initialState = {
  palettes: null,
  charts: null,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PALETTE_LOAD:
      return action;
    default:
      return state;
  }
};

export default reducer;
