import React from "react";
import ReactDOM from "react-dom";
import App from "./app.js";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import authReducer from "./redux/reducers/auth";
import progressReducer from "./redux/reducers/progress";
import userReducer from "./redux/reducers/user";
import uiReducer from "./redux/reducers/ui";
import { BrowserRouter as Router } from "react-router-dom";
import orgReducer from "./redux/reducers/org";
import jobReducer from "./redux/reducers/job";
import scenarioReducer from "./redux/reducers/scenario";
import paletteReducer from "./redux/reducers/palette";
import uiComponentReducer from "./redux/reducers/uicomponent";

//this is required for devextreme file-uploader to work.
let open = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function () {
  open.apply(this, arguments);
  this.withCredentials = true;
};

const rootReducer = combineReducers({
  progress: progressReducer,
  ui: uiReducer,
  auth: authReducer,
  user: userReducer,
  org: orgReducer,
  job: jobReducer,
  scenario: scenarioReducer,
  palette: paletteReducer,
  uicomponent: uiComponentReducer,
});

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("root")
);
