import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import HierarchyContext from "components/hierarchy/context";
import Box from "components/box";
import Valuation from "./hierarchy-dist";
import ConditionDist from "./condition-dist";
import Risk from "./risk-grid";
import { LoadingIndicator2 as LoadingIndicator } from "@kayugasolution/ui";
import { request } from "helper/http-client";
import { url } from "config.js";

function Component() {
  //
  //hooks

  const context = useContext(HierarchyContext);
  const selectedRowsData = context.selectedRowsData;
  const filter = context.filter;

  const [state, setState] = useState(null);

  useEffect(() => {
    setState(null);
    (async () => {
      const result = await request({
        url: `${url}/dbd/12/state`,
        params: { ids: selectedRowsData.map((i) => i.id), filterExpression: filter },
      });
      setState(result);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsData, filter]);

  //render
  return state ? (
    <div className={styles.main}>
      <div className={styles.grid}>
        <div className={styles.cell1}>
          <Box title="Risk Overview">
            <Risk data={state.risk} />
          </Box>
        </div>
        <div className={styles.cell2}>
          <ConditionDist data={state.conditionDistribution} />
        </div>
        <div className={styles.cell3}>
          <Valuation data={state.hierarchyDistribution} />
        </div>
      </div>
    </div>
  ) : (
    <LoadingIndicator />
  );
}

export default Component;
